<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :width="1100"
      :mask-closable="false"
      title="导入套餐"
      :ok-button-props="{ props: { loading: submitting } }"
      @ok="handleSubmit"
    >
      <import-product-search @submit="submitSearch" />

      <a-table
        size="middle"
        :columns="tableColumns"
        :data-source="tableData"
        :loading="loading"
        :pagination="false"
        :scroll="{ y: 350 }"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        row-key="id"
      />

      <a-row class="count-tips">
        共 {{ this.tableData.length }} 条记录，已选中 {{ this.selectedRowKeys.length }} 条数据
      </a-row>
    </a-modal>
  </div>
</template>

<script>
import { importAgentsProducts, findImportableAgentsProducts } from '@/api/agents_product'
import ImportProductSearch from '@/views/agents_products/top_agent_view_agency/import/Search'

export default {
  name: 'ImportAgentsProduct',
  components: { ImportProductSearch },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    agentId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      loading: true,
      selectedRowKeys: [],
      query: {},
      submitting: false,
      tableColumns: [
        {
          title: '套餐名称',
          dataIndex: 'name'
        },
        {
          title: '运营商种类',
          width: 120,
          dataIndex: 'carrier_type'
        },
        {
          title: '用户业务模式',
          width: 150,
          dataIndex: 'user_business_type'
        },
        {
          title: '套餐周期',
          width: 150,
          dataIndex: 'service_cycle'
        },
        {
          title: '套餐容量',
          width: 150,
          dataIndex: 'package_capacity'
        },
        {
          title: '语音',
          width: 80,
          dataIndex: 'voice_capacity'
        }
      ],
      tableData: []
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },

    fetchData() {
      this.loading = true
      this.selectedRowKeys = []
      findImportableAgentsProducts(Object.assign({ agent_id: this.agentId }, this.query)).then((res) => {
        if (res.code === 0) {
          this.tableData = res.data
        }
        this.loading = false
      })
    },

    submitSearch(search) {
      this.query = search
      this.fetchData()
    },

    handleSubmit() {
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      if (this.selectedRowKeys.length === 0) {
        this.$warning({
          title: '请选择操作对象',
          content: ''
        })
        return
      }

      const data = {
        agent_id: this.agentId,
        top_agents_product_ids: this.selectedRowKeys
      }

      this.submitting = true
      importAgentsProducts(data).then((res) => {
        // 关闭模态框
        this.isShow = false
        // 告知父组件已完成
        this.$emit('completed')
        this.submitting = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.count-tips {
  padding-top: 20px;
}
</style>
